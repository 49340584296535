(<template>
  <section class="documents">
    <div class="documents__heading-wrapper">
      <div class="documents__headers">
        <h3 class="documents__title">{{ sectionTitle }}</h3>
        <p class="documents__description">{{ sectionDescription }}</p>
      </div>
      <div class="documents__btn-wrapper">
        <button class="sk-btn sk-btn--default documents__upload-btn"
                @click="showUploadingModal">{{ $gettext('Upload') }}</button>
      </div>
    </div>
    <sk-input :placeholder="searchPlaceholder"
              :preselected-value="searchKey"
              :input-icon="'search'"
              class="documents__search"
              @enterpressed="searchDocuments"
              @fieldvaluechanged="searchDocuments" />
    <page-data-table :data="allDocuments"
                     :cells="tableCells"
                     :stripped="false"
                     :progress="progressActive"
                     :is-deletable="$isGodmode()"
                     :is-downloadable="true"
                     @downloaditem="catchDownload"
                     @deleteitem="catchDelete" />
    <div class="documents__pagination-cont">
      <items-amount class="documents__item-per-page"
                    @amountchanged="submitFilters" />
      <sk-pagination :current="+page"
                     :total="+pages"
                     class="documents__pagination"
                     @prev="toPrevPage"
                     @next="toNextPage"
                     @newpage="toNewPage" />
    </div>
  </section>
</template>)

<script>
  import {mapActions, mapState} from 'vuex';
  import PageDataTable from '@/components/shared_components/page_data/PageDataTable';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';
  import helpers from '@/helpers';

  export default {
    asyncData({store}) {
      const promisesArr = [];

      promisesArr.push(store.dispatch('DocumentCenterStore/getDocuments'));

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'items-amount': ItemsAmount,
      'page-data-table': PageDataTable
    },
    data() {
      return {
        progressActive: false,
        searchKey: '',
        requestTimer: ''
      };
    },
    computed: {
      ...mapState('DocumentCenterStore', {
        allDocuments: (state) => state.allDocuments.documents || [],
        count: (state) => state.allDocuments.count || 0,
        page: (state) => state.allDocuments.page || 1,
        pages: (state) => state.allDocuments.pages || 1
      }),
      tableCells() {
        return [
          {
            name: this.$gettext('File name'),
            dataField: 'filenameSearchValue',
            type: 'file'
          },
          {
            name: this.$gettext('Uploaded by'),
            dataField: 'creatorRole',
            type: 'uploadedBy'
          },
          {
            name: this.$gettext('Uploaded Date'),
            dataField: 'createdAt',
            type: 'custom',
            customFieldFunc: (rowData) => {
              return this.$moment(rowData).format('HH:mm, DD.MM.YYYY');
            }
          },
          {
            name: this.$gettext('Size'),
            dataField: 'byteSize',
            type: 'custom',
            customFieldFunc: (rowData) => {
              return helpers.numbers.formatBytes(rowData);
            }
          }
        ];
      },
      sectionTitle() { return this.$gettext('Documents'); },
      sectionDescription() { return this.$gettext('On this page you are able to exchange documents with the platform.'); },
      searchPlaceholder() { return this.$gettext('Search...'); }
    },
    methods: {
      ...mapActions('DocumentCenterStore', ['deleteDocument']),
      catchDelete(id) {
        this.progressActive = true;
        this.deleteDocument(id).then(() => {
          this.progressActive = false;
          this.submitFilters();
        }).catch(() => {
          this.progressActive = false;
        });
      },
      catchDownload(file) {
        const link = document.createElement('a');
        link.href = file.link;
        link.target = '_blank';
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      showUploadingModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'upload-doc-modal',
          data: {
            title: this.$gettext('Upload document'),
            context: this,
            successHandlingCallbackName: 'refreshDocumentsList'
          }
        });
      },
      resetFilterInfo() {
        this.searchKey = '';
        this.$store.state.DocumentCenterStore.allDocuments.page = 1;
        this.$store.state.DocumentCenterStore.allDocuments.pages = 1;
      },
      refreshDocumentsList() {
        this.resetFilterInfo();
        this.submitFilters();
      },
      toPrevPage() {
        this.$store.state.DocumentCenterStore.allDocuments.page -= 1;
        this.submitFilters();
      },
      toNewPage(page) {
        this.$store.state.DocumentCenterStore.allDocuments.page = +page;
        this.submitFilters();
      },
      toNextPage() {
        this.$store.state.DocumentCenterStore.allDocuments.page += 1;
        this.submitFilters();
      },
      submitFilters() {
        const params = {
          page: this.$store.state.DocumentCenterStore.allDocuments.page,
          items: this.$store.state.filterItemsAmount
        };
        if (this.searchKey) params['s[filename_search_value_cont]'] = this.searchKey;
        this.progressActive = true;
        this.$store.dispatch('DocumentCenterStore/getDocuments', params).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      searchDocuments(value) {
        clearInterval(this.requestTimer);
        this.searchKey = value;
        this.requestTimer = setTimeout(() => {
          this.submitFilters();
        }, 500);
      }
    },
    beforeDestroy() {
      this.$store.commit('DocumentCenterStore/clearStore');
    }
  };
</script>

<style>
.documents__search .sk-input__field-icon {
  left: 10px;
}

.documents__search .sk-input__input--with-icon {
  padding-right: 10px;
  padding-left: 40px;
  border-radius: 4px !important;
}
</style>
<style scoped>
  .documents {
    display: block;
    width: 100%;
    background: #fff;
  }

  .documents__heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px 24px 20px;
  }

  .documents__title {
    margin-bottom: 8px;
    font-size: 18px;
  }

  .documents__description {
    font-size: 12px;
  }

  .documents__search {
    padding: 0 20px;
  }

  .documents__upload-btn {
    display: inline-block;
    width: auto;
    padding: 0 25px;
  }

  .documents__pagination-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    border-top: 1px solid #e9eaef;
  }

  @media (max-width: 1024px) {
    .documents__pagination-cont {
      flex-direction: column;
    }

    .documents__item-per-page {
      margin: 0 auto 10px;
    }
  }
</style>
